<template>
  <div class="result-table-content">
    <!--    Dependant On    -->
    <CRow class="dep-row mb-3">
      <CCol col="5" sm="4" md="3" xl="2" class="col-form-label">
        <label>{{ label }}</label>
      </CCol>
      <CCol col="12" sm="6" md="4" xl="3" ref="canParticipate">
        <CButton
          variant="outline"
          color="dark"
          @click="toSelectPage"
          class="wizard-action-btn zq--custom-button"
          :disabled="disabled"
          name="canParticipate"
        >
          {{ buttonLabel }}
        </CButton>
      </CCol>
    </CRow>
    <div v-if="propData.selectedData.length">
      <!--          Table-->
      <CRow class="mr-0">
        <CCol col="12">
          <CCard class="zq-card-table">
            <CCardHeader v-if="valid === false">
              <span class="table-error">{{ errorMessage }}</span>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                id="achTableResult"
                class="zq--table"
                :items="selectedData"
                :fields="fields"
                :sorter="{ external: true }"
                :responsive="true"
                :border="isDefaultTheme"
                :hover="isDefaultTheme"
                :striped="isDefaultTheme"
              >
                <!--       Description           -->
                <template #description="{ item }">
                  <td>
                    <span v-html="item.description"></span>
                  </td>
                </template>
                <!--       Dependency           -->
                <template #dependency="{ item }">
                  <td>
                    <div class="result-table-content--dependency">
                      <div class="action-buttons-list">
                        <button
                          :disabled="disabled"
                          class="action-buttons action-buttons__must-not"
                          :class="{'action-buttons--active__must-not': formData.mustNot.includes(item.key)}"
                          @click="setDependantOn({key: item.key, type: 'mustNot'})"
                        >
                          MUST NOT
                        </button>
                        <button
                          :disabled="disabled"
                          class="action-buttons action-buttons__should"
                          :class="{'action-buttons--active__should': formData.should.includes(item.key)}"
                          @click="setDependantOn({key: item.key, type: 'should'})"
                        >
                          SHOULD
                        </button>
                        <button
                          :disabled="disabled"
                          class="action-buttons action-buttons__must"
                          :class="{'action-buttons--active__must':formData.must.includes(item.key)}"
                          @click="setDependantOn({key: item.key, type: 'must'})"
                        >
                          MUST
                        </button>
                      </div>
                      <div v-if="!disabled" class="delete-icon" @click="deleteTag(item.key)">
                        <i class="fa fa-times"></i>
                      </div>
                    </div>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <!--      Minimum Should Match -->
      <CRow v-if="formData.should.length >= 2">
        <CCol col="12" md="3" xl="2" class="text-left">
          <label class="col-form-label font-italic">Minimum Should Match*</label>
        </CCol>
        <CCol col="11" md="6" xl="4" class="position-relative">
          <CInput
            :disabled="disabled"
            type="number"
            placeholder="Minimum should match"
            v-model.number="formData.shouldMatchAtLeast"
            add-input-classes="col-sm-12"
            min="1"
            step="1"
            :max="formData.should.length >= 2 ? formData.should.length -1 : 1"
            @update:value="handleUpdateShouldMatch"
          />
          <IconWithTooltip :text="toolTipText"/>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {cloneDeep, startCase} from "lodash";
import {mapGetters} from "vuex";

export default {
  name: 'DepResultEntrantsTable',
  props: {
    toolTipText: String,
    storeType: {
      type: String,
      default: 'achievements'
    },
    propData: Object,
    isValid: Boolean,
    tagType: String,
    currentTypesProps: Object,
    label: {
      type: String,
      default: 'Restrict members to'
    },
    buttonLabel: {
      type: String,
      default: 'Select Member Tags'
    },
    errorMessage: {
      type: String,
      default: 'Dependencies are required for all selected member groups'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default() {
        return [
          {key: 'id', sorter: false},
          {key: 'name', sorter: false},
          {key: 'description', sorter: false},
          {key: 'dependency', sorter: false},
        ]
      }
    }
  },
  components: {
    IconWithTooltip
  },
  data() {
    return {
      selectedData: [],
      valid: null,
      currentTypes: {},
      formData: {
        must: [],
        mustNot: [],
        should: [],
        shouldMatchAtLeast: null,
      }
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    storeAction() {
      return `${this.storeType}/handleGet${startCase(this.storeType)}`
    },
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const results = await this.transformDependantOnToSelectedData(this.propData.formData);
      this.selectedData = this.propData.selectedData.length ? cloneDeep(this.propData.selectedData) : results;
      this.formData = cloneDeep(this.propData.formData);
      this.currentTypes = cloneDeep(this.currentTypesProps) ?? {};
      this.valid = this.isValid;
    },
    setDependantOn({key, type}) {
      if (!this.formData[type].includes((key))) {
        this.currentTypes[key] = type;
        this.formData[type].push(key);
        this.selectedData.find(item => {
          if (item.key === key) {
            item.valid = true;
            this.$refs.canParticipate.classList.remove('zq-invalid');
          }
        });

        this.$emit('updateCurrentTypes', this.currentTypes);
      }
      Object
        .keys(this.formData)
        .filter(item => item !== type)
        .map(item => {
          if (item !== 'shouldMatchAtLeast') {
            if (Array.isArray(this.formData[item])) {
              this.formData[item] = this.formData[item].filter(item => item !== key) || [];
            }
          }
        })
    },
    handleUpdateShouldMatch(val) {
      const value = parseInt(val)
      if (!value || value < 1) {
        this.formData.shouldMatchAtLeast = 1;
      } else if (val > this.formData.should.length - 1) {
        this.formData.shouldMatchAtLeast = this.formData.should.length - 1;
      } else {
        this.formData.shouldMatchAtLeast = value;
      }
    },
    deleteTag(key) {
      this.selectedData = this.selectedData.filter(item => item.key !== key);

      this.formData.must = this.formData.must.filter(item => item !== key);
      this.formData.mustNot = this.formData.mustNot.filter(item => item !== key);
      this.formData.should = this.formData.should.filter(item => item !== key);

      this.$emit('updateData', {formData: this.formData, selectedData: this.selectedData});
    },
    toSelectPage() {
      this.$emit('toSelectTable');
    },
    async transformDependantOnToSelectedData(val) {
      if (this.achievementType) {
        const selectedIds = [...val.must, ...val.mustNot, ...val.should];
        if (selectedIds.length) {
          const {results} = await this.$store.dispatch(this.storeAction, {idArray: selectedIds});
          return results
        }
      }
      return [];
    }
  },
  watch: {
    isValid(val) {
      this.valid = val;
    },
    propData: {
      deep: true,
      handler: function (val) {
        this.formData = val.formData;
        this.selectedData = val.selectedData;
        if (val.selectedData.every(item => item.valid)) {
          this.valid = null;
          this.$emit('resetValidation');
        }
      }
    },
    formData: {
      deep: true,
      handler: function (val) {
        if (val.should.length < 2) {
          val.shouldMatchAtLeast = 1;
        }
        this.$emit('updateData', {formData: val, selectedData: this.selectedData});
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";
.result-table-content {
  .fa-question-circle-o {
    position: absolute;
    height: 14px;
    top: 0;
    right: -7px;
  }
  .table-error {
    color: darkred;
  }
  .col-form-label {
    max-width: 220px;
  }
  .delete-icon {
    cursor: pointer;
    @include media-breakpoint-down(lg) {
      margin-left: 2rem;
    }
  }
  .result-table-content--dependency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
  }
  .zq-invalid {
    .zq--custom-button {
      border-color: var(--zq-warn);
    }
  }
}
</style>
